import { ref, reactive } from '@vue/composition-api'
import { isArrayParamEmpty, mapper } from '@/services/table/utils'

const usePagination = (state) => {
  const hasPagination = ref(false)
  const paginationParams = ref({
    total: 0,
    from: 0,
    to: 0
  })

  const updatePaginationParams = (params = {}, initialUpdate) => {
    if (initialUpdate) {
      const paramsIsInvalid = isArrayParamEmpty(Object.values(params))
      hasPagination.value = !paramsIsInvalid

      if (paramsIsInvalid) state = {}
    }

    if (!hasPagination.value) return

    const { total = 0, from = 0, to = 0 } = params
    paginationParams.value = { total, from, to }
  }

  const updateCurrentPage = (currentPage) => {
    if (!hasPagination.value) return

    state.page = currentPage
  }

  const typesMap = {
    perPage: (val) => Number(val),
    page: (val) => Number(val)
  }

  const queryToPaginationMapper = (query) => {
    mapper(state, query, typesMap)
  }

  const paginationToQueryMapper = () => {
    return { ...state }
  }

  const paginationToBackMapper = () => {
    return { ...state }
  }

  return {
    hasPagination,
    paginationParams,
    updatePaginationParams,
    updateCurrentPage,
    queryToPaginationMapper,
    paginationToQueryMapper,
    paginationToBackMapper
  }
}

const useLocalPagination = (hasPagination, perPage, initialItemsLength) => {
  const pagination = reactive({
    perPage: hasPagination ? perPage : initialItemsLength,
    page: 1
  })

  const paginationParams = ref({
    total: 0,
    from: 0,
    to: 0
  })

  const updatePaginationParams = (itemsLength) => {
    const from = (pagination.page - 1) * pagination.perPage + 1
    const supposedTo = (from - 1) + pagination.perPage
    const to = supposedTo > itemsLength
      ? itemsLength
      : supposedTo

    paginationParams.value = {
      total: itemsLength,
      from,
      to
    }
  }

  return {
    pagination,
    paginationParams,
    updatePaginationParams
  }
}

export {
  usePagination,
  useLocalPagination
}
