<template>
  <vx-card no-body>
    <template #title>
      {{ toUpperCase(groupName) }}
    </template>

    <template #actions>
      <vx-button
        size="sm"
        :can="canUpdate"
        :icon="actionButtons.icon"
        variant="primary"
        @click="openModal(fields)"
      >
        {{ actionButtons.label }}
      </vx-button>
    </template>

    <b-card-body>
      <b-tr v-for="({ title, id, value, viewFormatter }) in fields" :key="id">
        <b-td class="font-weight-bolder">
          {{ title }}
        </b-td>
        <b-td class="pt-1 pl-1">
          {{ viewFormatter ? viewFormatter(value) : value }}
        </b-td>
      </b-tr>
    </b-card-body>

    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle(groupName)"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <system-setting-editor
        :entity="entity"
        :group-name="groupName"
        @submit="handleSubmit"
      />
    </b-modal>
  </vx-card>
</template>

<script>
import { VxButton } from '@/components/buttons'
import VxCard from '@/components/VxCard'
import { BModal, BTr, BTd, BCardBody } from 'bootstrap-vue'
import { useModalEditor } from '@/services/modal'
import { upperFirst } from 'lodash'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { useCan, permissions } from '@/plugins/acl'
import { onBeforeMount, reactive, watch } from '@vue/composition-api'
import SystemSettingEditor from '@/views/settings/SystemSettingEditor'
import { settingsFieldsMap } from '@/views/settings/systemSettingsService'

export default {
  name: 'SettingCard',
  components: {
    VxCard,
    VxButton,
    SystemSettingEditor,
    BTr,
    BTd,
    BModal,
    BCardBody
  },
  props: {
    groupName: {
      type: String,
      required: true
    },
    group: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    const fields = reactive({})

    const backToFrontMapper = () => {
      props.group.forEach(({ name, ...rest }) => {
        fields[name] = {
          ...rest,
          title: formatFieldName(name),
          ...settingsFieldsMap[name]
        }
      })
    }

    onBeforeMount(() => backToFrontMapper())
    watch(() => props.group, () => backToFrontMapper())

    const formatFieldName = (name) => upperFirst(name.split('_').join(' '))
    const toUpperCase = (value) => upperFirst(value)

    const actionButtons = buttons.edit
    const canUpdate = useCan(permissions.settingUpdate)

    const {
      modalIsOpen,
      entity,
      openModal,
      closeModal
    } = useModalEditor()

    const modalTitle = (value) => `Edit ${toUpperCase(value)}`

    const handleSubmit = () => {
      closeModal()
      emit('submit')
    }

    return {
      fields,

      actionButtons,
      canUpdate,

      toUpperCase,

      entity,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
