import { moneyFormat, formatToCents, formatToDecimal } from '@/services/utils'
import { inputTypes } from '@/services/form/formService'

const integerRule = {
  required: true,
  integer: true,
  min_value: 1
}

const decimalRule = {
  required: true,
  decimal: true,
  min_value: 0.01
}

const priceFormatRules = {
  backToFrontMapper: formatToDecimal,
  frontToBackMapper: formatToCents,
  viewFormatter: moneyFormat,
  props: {
    step: 0.01
  }
}

export const settingsFieldsMap = {
  upgrade_max_chance: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 99999
    }
  },
  upgrade_min_available_amount: {
    type: inputTypes.number,
    rules: { ...integerRule }
  },
  upgrade_min_available_price: {
    type: inputTypes.number,
    rules: { ...decimalRule },
    ...priceFormatRules
  },
  upgrade_item_selection_range: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 10
    }
  },
  upgrade_min_upgradable_sum: {
    type: inputTypes.number,
    rules: { ...decimalRule },
    ...priceFormatRules
  },
  upgrade_profitability_coef: {
    type: inputTypes.number,
    rules: {
      decimal: true,
      min_value: 0.1,
      max_value: 1
    },
    props: {
      step: 0.01
    }
  },
  // Chest group
  chest_multi_opening_limit: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 10
    }
  },
  chest_auto_regeneration_limit: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 100
    }
  },
  // Exchange group
  exchange_choice_percent: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 10
    }
  },
  exchange_choice_limit: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      min_value: 2,
      max_value: 20
    }
  },
  exchange_min_available_amount: {
    type: inputTypes.number,
    rules: { ...integerRule }
  },
  // Player group
  player_nickname_keyword: {
    type: inputTypes.text,
    rules: {
      min: 1,
      max: 100
    }
  },
  // Drop group
  player_best_drop_min_price: {
    type: inputTypes.number,
    rules: { ...decimalRule },
    ...priceFormatRules
  },
  player_best_drop_period: {
    type: inputTypes.number,
    rules: { ...integerRule }
  },
  // Payment group
  payment_default_min_amount: {
    type: inputTypes.number,
    rules: {
      ...decimalRule,
      max_value: 1000
    },
    ...priceFormatRules
  },
  payment_default_suggested_amount: {
    type: inputTypes.number,
    rules: {
      ...decimalRule,
      max_value: 1000
    },
    ...priceFormatRules
  },
  payment_default_suggested_amount_list: {
    backToFrontMapper: (values) => values.map(formatToDecimal),
    frontToBackMapper: (values) => values.map(formatToCents),
    viewFormatter: (values) => values.map((value) => moneyFormat(value)).join(', '),
    type: inputTypes.select,
    props: {
      clearable: true,
      taggable: true,
      multiple: true,
      deselectFromDropdown: true,
      options: []
    },
    rules: {
      integer_array: true,
      max_length_array: 6,
      min_length_array: 1,
      max_item_value: 1000,
      min_item_value: 1
    }
  },
  // Gift card group
  gift_card_auto_upload_enabled: {
    type: inputTypes.checkbox,
    backToFrontMapper: (value) => Boolean(value)
  },
  gift_card_auto_create_new_keys_amount: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 1000
    }
  },
  gift_card_auto_upload_threshold: {
    type: inputTypes.number,
    rules: {
      ...integerRule,
      max_value: 1000
    }
  }
}
