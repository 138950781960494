var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_vm._l((_vm.entity),function(ref,name){
var type = ref.type;
var rules = ref.rules;
var title = ref.title;
var props = ref.props; if ( props === void 0 ) props = {};
return _c('vx-input-fabric',_vm._b({key:name,attrs:{"name":name,"label":title,"rules":rules,"input-type":type},model:{value:(_vm.formValue[name]),callback:function ($$v) {_vm.$set(_vm.formValue, name, $$v)},expression:"formValue[name]"}},'vx-input-fabric',props,false))}),_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }