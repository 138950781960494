<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input-fabric
      v-for="({ type, rules, title, props = {} }, name) in entity"
      :key="name"
      v-model="formValue[name]"
      v-bind="props"
      :name="name"
      :label="title"
      :rules="rules"
      :input-type="type"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { VxForm, VxInputFabric } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { passDataToResource, systemSettings } from '@/services/resources'
import { onBeforeMount, ref } from '@vue/composition-api'
import { mapValues } from 'lodash'
import { useToast } from '@/plugins/toastification'

export default {
  name: 'SystemSettingEditor',
  components: { VxForm, VxInputFabric, VxButton },
  props: {
    entity: {
      type: Object,
      default: null
    },
    groupName: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const toast = useToast()
    const formValue = ref({})

    const initialMapper = () => {
      formValue.value = mapValues(props.entity, ({ value, backToFrontMapper }) => {
        return backToFrontMapper ? backToFrontMapper(value) : value
      })
    }

    const frontToBackMapper = () => {
      return mapValues(formValue.value, (value, key) => {
        const frontToBack = props.entity[key]?.frontToBackMapper
        return frontToBack ? frontToBack(value) : value
      })
    }

    const formResource = passDataToResource(systemSettings.update, {
      frontToBackMapper,
      requestParams: {
        urlParams: { entity: props.groupName }
      }
    })

    onBeforeMount(() => initialMapper())

    const handleSubmit = ([, res]) => {
      if (res) {
        toast.success(`${props.groupName} has been successfully updated.`)
        emit('submit')
      }
    }

    return {
      formResource,
      formValue,
      handleSubmit
    }
  }
}
</script>
