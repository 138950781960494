import { VxInput, VxSelect, VxSelectResource, VxDateRangePicker } from '@/components/form'
import { escapeRegExp, labelFormat, rxSpaces } from '@/services/utils'
import { find, forEach, has, isArray, isEmpty } from 'lodash'
import { computed, set } from '@vue/composition-api'
import { isArrayParamEmpty, isParamEmpty } from '@/services/table/utils'

const filterDebounceThreshold = 500

const filterTypes = {
  text: 'text',
  number: 'number',
  select: 'select',
  serverSelect: 'serverSelect',
  dateRangePicker: 'dateRangePicker'
}

const filterDefaultDefsByType = {
  [filterTypes.text]: {
    type: filterTypes.text,
    value: '',
    debounce: filterDebounceThreshold,
    component: VxInput
  },
  [filterTypes.number]: {
    type: filterTypes.number,
    value: '',
    debounce: filterDebounceThreshold,
    component: VxInput
  },
  [filterTypes.select]: {
    type: filterTypes.select,
    value: null,
    component: VxSelect,
    options: []
  },
  [filterTypes.serverSelect]: {
    type: filterTypes.serverSelect,
    value: null,
    component: VxSelectResource
  },
  [filterTypes.dateRangePicker]: {
    type: filterTypes.dateRangePicker,
    value: [null, null],
    apiKeys: ['from', 'to'],
    component: VxDateRangePicker
  }
}

const getFilterDef = (filter) => {
  const { key, label, type, ...rest } = filter
  return {
    key,
    placeholder: label || labelFormat(key),
    ...type ? filterDefaultDefsByType[type] : filterDefaultDefsByType[filterTypes.text],
    ...rest
  }
}

const useFiltersBase = (state, filters = []) => {
  const hasFilters = Boolean(filters.length)
  // TODO: remove and use filterDefs instead of filterMaps
  const filtersMap = {}
  const filterDefs = []

  const setFilters = () => {
    filters.forEach((filter) => {
      const { value, key, ...rest } = getFilterDef(filter)

      set(state, key, value)
      filterDefs.push({ ...rest, key })
      filtersMap[key] = filter
    })
  }

  const getFilterDefByKey = (key) => {
    return find(filterDefs, { key })
  }

  const filterObjectByEmpty = (value) => {
    const res = {}
    forEach(value, (value, key) => {
      if (isParamEmpty(value)) return
      if (Array.isArray(value) && isArrayParamEmpty(value)) return

      res[key] = value
    })
    return res
  }

  return {
    hasFilters,
    filtersMap,
    filterDefs,
    setFilters,
    getFilterDefByKey,
    filterObjectByEmpty
  }
}

const useFilters = (state, filters = []) => {
  const {
    hasFilters,
    setFilters,
    filtersMap,
    filterDefs,
    getFilterDefByKey,
    filterObjectByEmpty
  } = useFiltersBase(state, filters)

  const queryToFilterMapper = (query) => {
    const newValues = {}

    forEach(state, (filter, key) => {
      if (!has(query, key)) return

      const queryValue = query[key]
      const { type, multiple } = getFilterDefByKey(key)
      switch (true) {
        case [filterTypes.serverSelect, filterTypes.select].includes(type) && multiple:
          newValues[key] = isArray(queryValue) ? queryValue.map(Number) : [Number(queryValue)]
          break
        case filterTypes.number === type:
          // TODO: check on NaN
          newValues[key] = Number(queryValue)
          break
        case filterTypes.dateRangePicker === type:
          // 2 is length of date range value
          newValues[key] = isArray(queryValue) && queryValue.length === 2
            ? queryValue
            : filterDefaultDefsByType[type].value
          break
        default:
          newValues[key] = queryValue
      }
    })

    Object.assign(state, newValues)
  }

  const filterToQueryMapper = () => {
    return filterObjectByEmpty(state)
  }

  const filterToBackMapper = () => {
    const filteredState = filterObjectByEmpty(state)
    const payload = {}

    forEach(filteredState, (value, key) => {
      const filter = filtersMap[key]
      if (filter.type === filterTypes.dateRangePicker && Array.isArray(value)) {
        const keysWithValues = {
          [filter.apiKeys[0]]: value[0],
          [filter.apiKeys[1]]: value[1]
        }
        Object.assign(payload, keysWithValues)
        return
      }

      payload[key] = value
    })

    return payload
  }

  return {
    filterDefs,
    hasFilters,
    setFilters,
    queryToFilterMapper,
    filterToQueryMapper,
    filterToBackMapper
  }
}

const useLocalFilters = (state, filters) => {
  const {
    hasFilters,
    setFilters,
    filtersMap,
    filterDefs,
    filterObjectByEmpty
  } = useFiltersBase(state, filters)

  const filterValueToTableMapper = computed(() => {
    const res = filterObjectByEmpty(state)
    return isEmpty(res) ? null : res
  })

  const filterComparator = (item, filterValue) => {
    const testResults = []
    // TODO: add support formatted values of item
    for (const key in filterValue) {
      // Escape special RegExp characters in the string and convert contiguous
      // whitespace to \s+ matches
      const pattern = escapeRegExp(filterValue[key]).replace(rxSpaces, '\\s+')
      // Build the RegExp (no need for global flag, as we only need
      // to find the value once in the string)
      const testResult = new RegExp(pattern, 'i').test(item[key])
      testResults.push(testResult)
    }

    return !testResults.includes(false)
  }

  return {
    hasFilters,
    filtersMap,
    filterDefs,
    filterValueToTableMapper,
    setFilters,
    filterComparator
  }
}

export {
  useFiltersBase,
  useFilters,
  useLocalFilters,
  getFilterDef,
  filterTypes
}
