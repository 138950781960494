import { forEach, has, isFunction, isNull, isUndefined } from 'lodash'

const isParamEmpty = (value) => {
  return isUndefined(value) || isNull(value) || value === ''
}

const isArrayParamEmpty = (array = []) => {
  if (!Array.isArray(array) || array.length === 0) return true

  const arrayOfIsEmpty = array.map((item) => isParamEmpty(item))
  return arrayOfIsEmpty.includes(true)
}

const tdAttrParse = (data, defaultValue = {}) => {
  const { value, item, field: { key, tdAttr = {} } } = data
  return {
    ...defaultValue,
    ...(isFunction(tdAttr) ? tdAttr(value, key, item) : tdAttr)
  }
}

const mapper = (target, source, map) => {
  forEach(target, (initialValue, key) => {
    if (!has(source, key)) return
    const value = source[key]

    target[key] = map[key] ? map[key](value) : value
  })
}

export {
  isParamEmpty,
  isArrayParamEmpty,
  tdAttrParse,
  mapper
}
