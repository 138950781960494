<template>
  <div class="grid-layout">
    <setting-card
      v-for="(group, name) in groups"
      :key="name"
      :group-name="name"
      :group="group"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { groupBy } from 'lodash'
import SettingCard from '@/views/settings/SettingCard'
import { systemSettings, useResource } from '@/services/resources'
import { useToast } from '@/plugins/toastification'

export default {
  name: 'SystemSettings',
  components: {
    SettingCard,
    BRow,
    BCol
  },
  props: {
    dataResolverResponse: {
      type: Array,
      default: null
    }
  },
  setup (props) {
    const toast = useToast()
    const { callRequest } = useResource(systemSettings.getAll)

    const backToFrontMapper = (values) => {
      groups.value = groupBy(values, 'group')
    }

    const groups = ref({})
    backToFrontMapper(props.dataResolverResponse)

    const getSystemSettings = async () => {
      const [err, res] = await callRequest()
      if (err) {
        toast.error(err)
        return
      }

      backToFrontMapper(res.data)
    }

    const handleSubmit = async () => {
      await getSystemSettings()
    }

    return {
      groups,
      handleSubmit
    }
  }
}
</script>
<style lang="scss" scoped>
.grid-layout {
  columns: 2;
  gap: 1em;

  & > * {
    break-inside: avoid;
    margin-bottom: 1rem;
  }
}
</style>
