import { has, isUndefined } from 'lodash'
import router from '@/router'
import { isParamEmpty, mapper } from '@/services/table'

const sortOrders = {
  asc: false,
  desc: true
}

const useSorting = (state, columns) => {
  const setInitialSorting = () => {
    if (!isUndefined(router.currentRoute.query?.sortBy)) return

    const column = columns.find(item => has(item, 'defaultSort'))
    if (!column) return

    Object.assign(state, {
      sortBy: column.key,
      sortDesc: column.defaultSort
    })
  }

  const typesMap = {
    sortDesc: (val) => JSON.parse(val)
  }

  const queryToSortingMapper = (query) => {
    mapper(state, query, typesMap)
  }

  const sortingToQueryMapper = () => {
    return state.sortBy ? state : {}
  }

  const sortingToBackMapper = () => {
    const { sortBy, sortDesc } = state

    return !isParamEmpty(sortBy)
      ? { sortBy: sortDesc ? `-${sortBy}` : sortBy }
      : {}
  }

  return {
    setInitialSorting,
    queryToSortingMapper,
    sortingToQueryMapper,
    sortingToBackMapper
  }
}

export {
  sortOrders,
  useSorting
}
